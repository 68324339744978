<template>
  <div class="app-not-found" style="background: no-repeat center/cover url('/api/v1/assets/login_background')">
    <div class="app-not-found__image"></div>
    <div class="app-not-found__title">{{ $t("pageNotFound") }}</div>
  </div>
</template>

<script>
export default {
  name: 'app-not-found'
};
</script>

<style lang="less">
html,body {
  height: 100%;
}
.app-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .app-not-found__image {
    width: 380px;
    height: 180px;
    background: no-repeat center/cover url("../../assets/404.png");
  }
  .app-not-found__title {
    margin-top: 32px;
    font-size: 32px;
  }
}
</style>
